<template>
  <div class="item">
    <el-menu
       :default-active="path"
      class="el-menu-vertical-demo"
      :default-openeds="openeds"
      active-text-color="#409EFF"
      router
      @open="handleOpen"
      @close="handleClose"
    >
      <el-menu-item index="/my/myOftenBuy">
        <img class="iconBox" src="@/assets/myOftenBuyIcon.png" alt="myOftenBuyIcon">
        <span slot="title">最近购买</span>
      </el-menu-item>
       <el-menu-item index="/my/myorder">
         <img class="iconBox" src="@/assets/myorderIcon.png" alt="myorderIcon">
        <span slot="title">我的订单</span>
      </el-menu-item>


      <el-menu-item   v-if="MyContrilFlag" class="submenu" index="/my/MyControlPin">
        <img class="iconBox" src="@/assets/kongxiao.png" alt="afterRefundIcon">
        <span slot="title">我的控销</span>
      </el-menu-item>


       <el-menu-item index="/my/afterRefund">
        <img class="iconBox" src="@/assets/afterRefundIcon.png" alt="afterRefundIcon">
        <span slot="title">我的售后</span>
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">
          <img class="iconBoxs" src="@/assets/myWalletIcon.png" alt="myWalletIcon">
          <span>我的资产</span>
        </template>
        <el-menu-item-group>
           <!-- <el-menu-item index="/my/">个人中心</el-menu-item>  -->
          <el-menu-item index="/my/balance">我的预存款</el-menu-item>
          <el-menu-item index="/my/coupon">我的优惠券</el-menu-item>
            <el-menu-item index="/my/points">我的药豆</el-menu-item>
             <el-menu-item index="/my/memberGiftList">我的赠品</el-menu-item>
              <el-menu-item v-if="creditShow"  index="/credit">我的授信</el-menu-item>
          <!-- <el-menu-item index="1-2"><router-link :to="{name:'Credit'}" tag="div">我的授信</router-link></el-menu-item> -->
          <!-- <el-menu-item index="/my/FlyCalc">我的飞贷</el-menu-item> -->
        </el-menu-item-group>
      </el-submenu>
      
      <el-menu-item index="/my/natural">
        <!-- v-show="naturalShow" -->
          <img class="iconBox" src="@/assets/mynaturalIcon.png" alt="mynaturalIcon">
        <span slot="title">我的资质</span>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <img class="iconBoxs" src="@/assets/mysettingIcon.png" alt="mysettingIcon">
          <span>账号设置</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/my/myInvoice">账号信息</el-menu-item>
          <el-menu-item index="/my/myAssociatedAccount">关联门店</el-menu-item>
          <el-menu-item index="/my/myAssociationMemberStoreDetail"   v-show="chainShow">连锁设置</el-menu-item>
          <el-menu-item index="/my/myConsigneeInfo">收货地址</el-menu-item>
          <el-menu-item   index="/my/changemobile">修改登录账号</el-menu-item>
          <el-menu-item index="/my/repass">修改登录密码</el-menu-item>
          <el-menu-item  v-if="isSetPayPassword==0" index="/my/paypass">设置支付密码</el-menu-item>
          <el-menu-item  v-if="isSetPayPassword==1" index="/my/repaypass">修改支付密码</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
     
     
     
      <!-- <el-menu-item index="/my/report">
        <span slot="title">厂检报告</span>
      </el-menu-item> -->
      <!-- <el-menu-item index="/my/firstInfo">
          <img class="iconBox" src="@/assets/myfirstInfoIcon.png" alt="wtsDownIcon">
        <span slot="title">首营资料</span>
      </el-menu-item>
      <el-menu-item index="/my/wtsDown">
        <img class="iconBox" src="@/assets/wtsDownIcon.png" alt="wtsDownIcon">
        <span slot="title">委托书下载</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
import { MemberInfo } from "api/member.js";//GetMemberStore
import {getCurrentMemberStoreCreditStatus} from "api/creditPay.js";
import { isControlFlag } from "api/product.js";
export default {
  name: "Asider",
  data() {
    return {
      // openeds: sessionStorage.getItem('openeds')?JSON.parse(sessionStorage.getItem('openeds')):[],
      openeds:['1','2'],
      boolean: true,
      path:'',
      isSetPayPassword:0,   //是否设置支付密码 1设置，0未设置
      naturalShow:false,
      chainShow:false, // 是否显示连锁设置
      creditShow:false,    //我的授信状态   -1 未开通   0:审核失败  1:正常  2:审核中   3:冻结
      
      MyContrilFlag:false, // 我的控销 开关
    };
  },
    methods: {
      handleClose(key){
        let index=this.openeds.indexOf(key)
        this.openeds.splice(index, 1);
         sessionStorage.setItem('openeds',JSON.stringify(this.openeds))
      },
      handleOpen(key){
        if(this.openeds.length===0){
           this.openeds.push(key);
            sessionStorage.setItem('openeds',JSON.stringify(this.openeds))
        }else{
          if(this.openeds.indexOf(key)==-1){
            this.openeds.push(key);
            sessionStorage.setItem('openeds',JSON.stringify(this.openeds))
          }
        }
      
       
      },
    onRouteChanged () {
      let that = this
      that.path  = that.$route.path
        if(that.path=="/my/repass"  || that.path=="/my/myAssociatedAccount"  || that.path=="/my/myConsigneeInfo"|| that.path=="/my/myInvoice" || that.path=="/my/paypass"  || that.path=="/my/repaypass"){
           if(this.openeds.indexOf("2")==-1){
              this.openeds.push("2");
           }
         
        }else  if(that.path=="/my/balance" || that.path=="/my/coupon" ){
           if(this.openeds.indexOf("1")==-1){
              this.openeds.push("1");
           }
        }else{
          sessionStorage.setItem('openeds',JSON.stringify(this.openeds))
        }
              sessionStorage.setItem('openeds',JSON.stringify(this.openeds))
     
    }
  },
  created () {

    isControlFlag().then(res=>{
          this.MyContrilFlag=res.data.data
        // console.log('llllllllllllllllllllllllllll',res.data.data)
    })



    this.onRouteChanged()
         //  获取当前门店的授信状态     status:{ -1 未开通   0:审核失败  1:正常  2:审核中   3:冻结}
    getCurrentMemberStoreCreditStatus().then(res=>{
      if(res.data.data.status===1 || res.data.data.status===3 ){
        this.creditShow=true
      }else{
         this.creditShow=false
      }
    })
       MemberInfo().then((res) => {
          if(res.data.data.auditStatus==3 || res.data.data.auditStatus==4 ){
           this.naturalShow=true
         }

          // 是否显示连锁设置   parentErpNumber
             if(res.data.data.parentErpNumber==''){
                this.chainShow=false
              }else{
                this.chainShow=true
              }
            this.isSetPayPassword = res.data.data.isSetPayPassword;
       })
  },
  watch: {
    // 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
    '$route': 'onRouteChanged'
  }
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.iconBox{
  position: absolute;
  top: 18px;
    left: 30px;
  width: 16px;
  height: 16px;
}
.iconBoxs{
  position: absolute;
  top:21px;
    left: 30px;
  width: 16px;
  height: 16px;
}
.item {
  margin-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  overflow: hidden;
  .el-menu-item:hover,
  .el-submenu span:hover {
    color: #F43C38 ;
  }
}
/deep/.el-menu-item.is-active {
    color: #F43C38 !important;
}
/deep/.el-menu-item{
  height:50px;
    line-height:50px;
    font-weight: bold;
color: #333333;
font-size: 14px;
text-indent:40px;
}
/deep/.el-menu-item-group__title{
  padding:0;
}
/deep/.el-submenu__title{
font-weight: bold;
color: #333333;
font-size: 14px;
  text-indent:40px;
}
/deep/.el-submenu__icon-arrow{
    display: none;
}
/deep/.el-submenu.is-active .el-submenu__title{
 font-weight: bold;
color: #333333;
font-size: 14px;
}
/deep/.el-submenu .el-menu-item{
    height:30px;
    line-height:30px;
    font-weight: normal;
    color: #333333;
    font-size: 14px;
text-indent:20px;
}
</style>
